<template>
  <div class="doc-container container">
    <h2 class="h2_title">免责声明</h2>
    <p>
      欢迎您光临武汉佰悦互娱科技有限公司游戏平台（以下称"本平台"）。在您使用本平台各项服务之前，请仔细阅读本《免责声明》。本平台为武汉佰悦互娱科技有限公司运营的游戏平台，域名为bytefun.link。<b
        >凡以任何方式登陆浏览本平台内容或直接、间接使用本平台内容者，视为同意本平台《免责声明》。</b
      >
    </p>
    <ol>
      <li>本平台用户应依法对其提供的内容承担全部责任，本平台不承担任何责任。</li>
      <li>本平台用户上传的内容仅代表作者观点，不代表本平台观点。</li>
      <li>
        本平台内容，包括以链接形式推荐的其他网站内容，在法律允许的最大范围内，本平台不担保这些内容、产品、服务或其他材料的真实性、完整性、安全性和合法性。用户应谨慎判断并自担风险，避免任何因使用或信赖此类内容、产品、服务或其他材料而造成的任何直接或间接损失。
      </li>
      <li>本平台内容仅供参考，不做为任何行为的指导和建议。</li>
      <li>
        本平台内容受到著作权法、商标法、专利法、信息网络传播权保护条例等相关法律法规的保护和约束，除非获得权利人的授权，用户不得转载、修改、传播、制作衍生作品或者商业使用。用户或其他媒体、企业、网站、其他组织或个人对本平台内容如需使用，必须先与作者、相关权利人或本平台联系。
      </li>
      <li>
        本平台可能因合作方或相关电信部门的互联网软硬件设备故障或失灵、或人为操作疏忽而全部或部分中断、延迟、遗漏、误导或造成资料传输或储存上的错误、或遭第三人侵入系统篡改或伪造变造资料等，对此用户同意理解和接受。
      </li>
      <li>本平台可能因黑客攻击、计算机病毒侵入或发作、政府管制而造成的暂时性关闭，或因前述原因以及与本网站链接的其它网站原因导致个人资料泄露、丢失、被盗用或被篡改等，对此用户同意理解和接受。</li>
      <li>本平台有权在任何时候，修改或暂停、终止本平台全部或部分服务，对此用户可以理解和接受。</li>
    </ol>
    <b>权力保护</b>
    <p>
      如果您是某一作品的著作权人和/或依法可以行使信息网络传播权的权利人，且您认为本平台内容侵犯了您对该等作品的合法权益，请您务必书面通知本平台。您应对书面通知陈述之真实性负责。<br />
      您的通知文件应包含以下内容和材料：
    </p>
    <ol>
      <li>您的姓名（或名称）、联系方式（电话、E-mail）和地址；</li>
      <li>要求删除的作品的准确名称、截图及其在本平台上的链接地址；</li>
      <li>构成侵权的初步证明材料，包括但不限于您对该作品享有著作权或其他权利的权属证明、以及证明侵权事实的材料。</li>
      <li>您的签名和（或）盖章。</li>
    </ol>
    <p>
      请您将上述材料邮寄到以下地址：<br />
      收件人：武汉佰悦互娱科技有限公司法务部 <br />
      地址：武汉市东湖高新区光谷大道现代光谷世贸中心H栋503<br />
      邮编：430223
    </p>
  </div>
</template>

<script>
export default {
  name: 'HelpDoc3'
}
</script>

<style lang="scss" scoped>
.doc-container {
  margin: 2rem auto !important;
  min-height: 72vh;
  ol {
    margin-left: 2rem;
  }
  // 公共样式
  h2 {
    color: black;
  }
}
</style>
